import AccountTypes from '@/common/enums/accountTypeEnum'
import memberPermissionsEnum from '@/common/enums/memberPermissionsEnum'

export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    // authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'General',
    route: 'general',
    icon: 'ListIcon',
    authorizedUsers: [AccountTypes.USER],
    children: [
      {
        icon: 'UserIcon',
        title: 'Profiles',
        route: 'profiles',
      },
      {
        icon: 'FileIcon',
        title: 'Business Details',
        route: 'business-details',
      },
      {
        icon: 'CreditCardIcon',
        title: 'Billing Details',
        route: 'billing-details',
      },
    ],
  },
  {
    title: 'Businesses',
    route: 'groups',
    icon: 'UsersIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
  {
    title: 'Venues & Screens',
    route: 'venues',
    icon: 'TrelloIcon',
    authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'Media Storage',
    route: 'media-storage',
    icon: 'ImageIcon',
    // requiredPermission: memberPermissionsEnum.MEDIA,
    authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'Creative Studio',
    route: 'creative-studio',
    icon: 'Edit3Icon',
    // requiredPermission: memberPermissionsEnum.MEDIA,
    // authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'Reports',
    route: 'report',
    icon: 'FileTextIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
  {
    title: 'Non-Configured',
    route: 'nonconfig',
    icon: 'ZapOffIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
  {
    title: 'Businesses on Trial',
    route: 'businessTrial',
    icon: 'UserXIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
  {
    title: 'All Screens',
    route: 'screenList',
    icon: 'MonitorIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
  {
    title: 'External Ad Campaign List',
    route: 'pmpAdsList',
    icon: 'GlobeIcon',
    authorizedUsers: [AccountTypes.ADMIN],
  },
  {
    title: 'Internal Ad Campaigns',
    route: 'banner-ads',
    icon: 'BookIcon',
    requiredPermission: memberPermissionsEnum.INTERNAL_NETWORK_ADS,
    authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'External Ad Campaigns',
    route: 'pmp-ads',
    icon: 'GlobeIcon',
    requiredPermission: memberPermissionsEnum.PMP_ADS,
    authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'Reports',
    route: 'report-page',
    icon: 'FileTextIcon',
    requiredPermission: memberPermissionsEnum.REPORT,
    authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'Target Ads',
    route: 'target-ads',
    icon: 'GlobeIcon',
    requiredPermission: memberPermissionsEnum.TARGET_ADS,
    authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'Academy',
    route: 'academy',
    icon: 'AwardIcon',
    authorizedUsers: [AccountTypes.USER],
  },
  {
    title: 'Support',
    route: 'support',
    icon: 'HelpCircleIcon',
    authorizedUsers: [AccountTypes.USER],
  },
]
