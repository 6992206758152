<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <span v-if="!isUserAdmin">
          <groups-list-dropdown />
        </span>
      </li>
    </ul>

    <!-- Left Col -->
    <!--    <notification-dropdown />-->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ currentUser.name }}
            </p>
            <span class="user-status">
              {{ (currentUser.user_has_role && currentUser.user_has_role.length > 0 && currentUser.user_has_role[0].role) ? currentUser.user_has_role[0].role.role : '' }}
            </span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="currentUser.image?currentUser.image: require('@/assets/images/avatars/User-place-holder.svg')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>
        <b-dropdown-item
          v-if="!isUserAdmin"
          :to="{ name: 'account-setting' }"
          link-class="d-flex align-items-center"
        >
          <feather-icon
            size="16"
            icon="SettingsIcon"
            class="mr-50"
          />
          <span>Settings</span>
        </b-dropdown-item>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="signOutLocal"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar, BLink,
} from 'bootstrap-vue'
// import NotificationDropdown from '@core/layouts/components/app-navbar/components/NotificationDropdown.vue'
// import { getAuth, signOut } from 'firebase/auth'
import GroupsListDropdown from '@/components/group-members/GroupsListDropdown.vue'

export default {
  components: {
    GroupsListDropdown,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
  },

  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters['user/getUser']
    },
    getGroups() {
      return this.$store.getters['user/getAllUserGroups']
    },
    isUserAdmin() {
      return this.$store.getters['user/isUserAdmin']
    },
  },
  methods: {
    async signOutLocal() {
      localStorage.removeItem('canva-token')
      localStorage.removeItem('canvaCode')
      await this.$store.dispatch('user/logoutUserWithRedirect')
    },
  },
}
</script>
